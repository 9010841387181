/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import './nanogallery2/nanogallery2.woff.min.css'
require('./nanogallery2/jquery.nanogallery2mdf.js');

// start the Stimulus application
//import './bootstrap';

const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');


//require('screenfull');
// create global $ and jQuery variables
//global.$ = global.jQuery = $;

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});

var sel_year = new Date().getFullYear();

$('.year-sel').on('click', function () {

    sel_year = this.id
    $('.year-sel-button').html(sel_year);
})

$('#login-form').submit(function() {
    let input = $("input[name=_username]");
    if (input.val().length === 2) input.val(sel_year + '-' + input.val());
});